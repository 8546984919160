<template>
  <div>
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="site-navbar-logo-box">
              <img
                :src="Logo"
                style="max-width:100px"
              >
              <h4 class="text-white">
                Mortgage Envy Corporation
              </h4>

            </div>

            <p
              v-if="footerContent.hwa"
              class="mt-2 mb-2 text-white"
            >
              {{ footerContent.hwa }}
            </p>
            <p
              v-else
              class="mt-2 mb-2 text-white"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.
            </p>
            <div class="footer-social-round-list">
              <div class="site-social-icon-round">
                <feather-icon icon="FacebookIcon" />
              </div>

              <div class="site-social-icon-round">
                <feather-icon icon="InstagramIcon" />
              </div>

              <div class="site-social-icon-round">
                <feather-icon icon="TwitterIcon" />
              </div>

              <div class="site-social-icon-round">
                <feather-icon icon="LinkedinIcon" />
              </div>
            </div>

          </div>
          <div class="col-md-2">
            <h3 class="footer-headings">
              Learn
            </h3>
            <div class="footer-link-box">
              <template v-for="(item) in footerContent.lean">
                <span
                :key="item.id"
                v-if="item.visible"
                @click="openUrl(item.url)"
              >
              
              {{ item.label }}
            
            </span>
              </template>
             
            </div>
          </div>
          <div class="col-md-2">
            <h3 class="footer-headings">
              Company
            </h3>
            <div class="footer-link-box">
              <template  v-for="(item) in footerContent.company">
                <span
               v-if="item.visible"
                :key="item.id"
                @click="openUrl(item.url)"
              >{{ item.label }}</span>
              </template>
          </div>
          </div>
          <div class="col-md-2">
            <h3 class="footer-headings">
              Legal
            </h3>
            <div class="footer-link-box">
              <template v-for="(item) in footerContent.legal">
                <span
                v-if="item.visible"
                :key="item.id"
                @click="openUrl(item.url)"
              >{{ item.label }}</span>
              </template>
            </div>
          </div>
          <div class="col-md-3">
            <h3 class="footer-headings">
              Contact
            </h3>
            <div class="footer-contact-box">
              <div class="contact-text">
                <feather-icon
                  class="footer-contact-icon"
                  icon="PhoneCallIcon"
                />
                <span>{{ contactInfo.phone }}</span>
              </div>
              <div class="contact-text">
                <feather-icon
                  class="footer-contact-icon"
                  icon="MailIcon"
                />
                <span>{{ contactInfo.email }}</span>
              </div>
              <div class="contact-text">
                <feather-icon
                  class="footer-contact-icon"
                  icon="MapPinIcon"
                />
                <span v-html="contactInfo.address" />
              </div>
            </div>
          </div>
        </div>

        <div class="footer-copyright-view">
          <p class="text-white text-center">
            Copyright @2022 Mortgage Envy Corporation
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Logo from '@/assets/log2.png'

export default {
  data() {
    return {
      Logo,
    }
  },
  computed: {
    footerContent() {
      return this.$store.getters['auth/footerContent']
    },
    contactInfo() {
      return this.$store.getters['auth/contactContent']
    },
  },
  mounted() {
    this.getFooterContent()
  },
  methods: {
    getFooterContent() {
      this.$http.get('api/footer-content')
        .then(res => {
          this.$store.commit('auth/setFooterContent', res.data)
          this.loaded = true
        })
    },
    openUrl(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style>

</style>
