<template>
  <div>
    <bread-creamb title="Glossary" />
    <section class="blog-view">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <!-- blog content view start -->
            <div class="blog-content-view">
              <app-collapse accordion>
                <app-collapse-item v-for="(gloss, index) in glossary.arr_content" :key="index" :title="gloss.term">
                  {{ gloss.meaning }}
                </app-collapse-item>
              </app-collapse>
            </div>
            <!-- blog content view end -->
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppFooter from './site-content/AppFooter.vue'
import Blog from './site-content/Blog.vue'
import Navbar from './site-content/Navbar.vue'
import Subscribe from './site-content/Subscribe.vue'
import BreadCreamb from './site-pages/BreadCreamb.vue'

export default {
  components: {
    Navbar,
    BreadCreamb,
    AppFooter,
    Subscribe,
    Blog,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {

    }
  },
  computed: {
    glossary() {
      let glossary = this.$store.getters['auth/siteContent']('glossary')
      glossary?.arr_content.sort((a, b) => {
        var termA = a.term?.toLowerCase(), termB = b.term?.toLowerCase();
        if (termA < termB)
          return -1;
        if (termA > termB)
          return 1;
        return 0;
      })
      return glossary;
    },
  },
}
</script>

<style>

</style>
