<template>
  <section
    id="hwa-blog"
    class="blog-view-section"
  >
    <div class="container">
      <h1 class="page-heading-2">
        NEWS & BLOG
      </h1>
      <h1 class="heading-text-2">
        Featured News And Insights
      </h1>
      <div class="row mt-5">
        <!-- blog content start -->
        <div
          v-for="(item, index) in posts"
          :key="index"
          class="col-md-4 col-lg-4"
          @click="showContent(item.slug)"
        >
          <div class="blog-view-card">
            <div
              class="blog_image"
              :style="getImage(item.image)"
            />

            <div class="blog-view-body">
              <div class="comment-view">
                <div class="date-item">
                  <feather-icon
                    class="icon-view"
                    icon="ClockIcon"
                  /> <span>{{ date(item.created_at) }}</span>
                </div>
                <div class="comment-item">
                  <feather-icon
                    class="icon-view"
                    icon="MessageSquareIcon"
                  /> <span>0 Comments</span>
                </div>
              </div>

              <h4 class="mt-2 mb-2 blog-heading">
                {{ item.title | setTruncate(35,"...") }}
              </h4>
              <hr>
              <div class="author-view">
                <b-avatar v-if="item.author && item.author.avatar === null" />
                <b-avatar
                  v-else
                  :src="getAvatar(item.author.avatar)"
                />
                <span>{{item.author && item.author.user_info.full_name }}, Author</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { BAvatar, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  computed: {
    posts() {
      return this.$store.state.site.latest_post
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.getPosts()
  },
  methods: {
    getPosts() {
      this.$store.dispatch('site/allPosts')
    },
    getAvatar(image) {
      if (image === undefined || image === null) return null
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    getImage(image) {
      return `background: url(${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${image})`
    },
    showContent(title) {
      this.$router.push(`/blog/${title}`).catch(() => { })
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
  },
}
</script>

<style>

</style>
