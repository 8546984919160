<template>
  <div>
    <!-- navbar section start -->
    <section class="site-navbar-main-view">
      <div class="container-fluid">
        <div class="site-navbar-box">
          <div class="site-navbar-logo-box">
            <!-- <div class="logo-item">
              M<span class="logo-item-text">E</span>
            </div> -->
            <img :src="Logo" style="max-width:100px">
            <b-link to="/">
              <h4 style="cursor:pointer">
                Mortgage <span class="logo-text">Envy</span> Corporation
              </h4>
            </b-link>
          </div>
          <div v-if="$route.path === '/'" class="site-navbar-menu">
            <span @click="$router.push('/').catch(() => { })">Home</span>
            <span v-scroll-to="'#hwa-about-us'">About</span>
            <span v-scroll-to="'#hwa-service'">Mortgage Types</span>
            <!-- <span v-scroll-to="'#hwa-resources'">Resources</span> -->
             <div class="menu-list-item-box">
            <span>Blog</span>
            <div class="dropdown__menu__box">
              <div class="link_items" v-scroll-to="'#hwa-blog'">Featured</div>
              <div class="link_items" @click="$router.push('/blog').catch(()=>{})">Blogs</div>
            </div>
          </div>
            <span v-scroll-to="'#hwa-contact'">Contact</span>
          </div>
          <div v-else class="site-navbar-menu">
            <span @click="$router.push('/').catch(() => { })">Home</span>
            <span @click="$router.push('/about').catch(() => { })">About</span>
            <span @click="$router.push('/mortgage-type').catch(() => { })">Mortgage Types</span>
            <!-- <span @click="$router.push('/resources').catch(() => { })">Resources</span> -->
            <span  @click="$router.push('/blog').catch(() => { })">Blog</span> 
            <span @click="$router.push('/contact').catch(() => { })">Contact</span>
          </div>
          <div class="site-navbar-button-view">
            <div v-if="!isLoggedIn" class="site-navbar-buttons dry" @click="navigateTo('login')">
              Login
            </div>
            <div v-if="isLoggedIn" class="site-navbar-buttons dry" @click="navigateToAccount()">
              Account
            </div>
            <div class="site-navbar-buttons dry" @click="inProgress ? continueQuestions() : getStarted()">
              {{ inProgress?'Continue': 'Start Now' }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- navbar section end -->

    <!-- navbar section start -->
    <section class="drop-down-navbar">
      <div class="container-fluid">
        <div class="site-navbar-box">
          <div class="site-navbar-logo-box">
            <!-- <div class="logo-item">
              M<span class="logo-item-text">E</span>
            </div> -->
            <b-link to="/">
              <h4 style="cursor:pointer">
                Mortgage <span class="logo-text">Envy</span> Corporation
              </h4>
            </b-link>
          </div>
          <div class="site-navbar-button-view">
            <div class="colaspe-menu" @click="handleShowMenu">
              <feather-icon class="icon-view" icon="MenuIcon" size="30" />
            </div>
          </div>
        </div>
        <div v-if="is_show_menu" class="menu-list-view">
          <div class="menu-list-item-box">
            <span v-scroll-to="'#hwa-home'">Home</span>
          </div>
          <div class="menu-list-item-box">
            <span v-scroll-to="'#hwa-about-us'">About</span>
          </div>
          <div class="menu-list-item-box">
            <span v-scroll-to="'#hwa-service'">Services</span>
          </div>
          <div class="menu-list-item-box">
            <span v-scroll-to="'#hwa-resources'">Resources</span>
          </div>
          <div class="menu-list-item-box">
            <span>Blog</span>
            <div class="dropdown__menu__box">
              <div class="link_items" v-scroll-to="'#hwa-blog'">Featured</div>
              <div class="link_items" @click="$router.push('/blog').catch(()=>{})">Blogs</div>
            </div>
          </div>
          <div class="menu-list-item-box">
            <span v-scroll-to="'#hwa-blog'">Contact</span>
          </div>
        </div>
      </div>
    </section>
    <!-- navbar section end -->
  </div>
</template>

<script>
import navigateToHome from '@/mixins/navigateToHome'
import { BLink, BPopover, BListGroup, BListGroupItem } from 'bootstrap-vue'
import Logo from '@/assets/log2.png'

export default {
  components: {
    BLink,
    BPopover, BListGroup, BListGroupItem
  },
  mixins: [navigateToHome],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      loaded: false,
      show: false,
      type: null,
      Logo,
      is_show_menu: false,
      options: [{ text: 'Account Type', value: null }, { text: 'Client', value: 0 }, { text: 'Real Estate Agent', value: 1 }],
    }
  },
  computed: {
    faqs() {
      return this.$store.getters['auth/faqs']
    },
    reviews() {
      return this.$store.getters['auth/reviews']
    },
    isPublic() {
      return this.$route.meta.public
    },
    inProgress() {
      return this.stepData?.step > 0
    },
    stepData() {
      return this.$store.getters['auth/stepData']
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    navMenuHidden() {
      return this.$store.state.appConfig.layout.menu.hidden
    },
    appBarData() {
      return this.$store.getters['auth/appBarData']
    },
  },
  created() {
    this.getSiteContents()
  },
  mounted() {
    this.getProgress()
  },
  methods: {
    continueQuestions() {
      const { step } = this.stepData
      const { type } = this.stepData
      let route = 'step-'
      // eslint-disable-next-line default-case
      switch (step) {
        case 1:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.getStartedCompleted = true
          break
        case 2:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.stepOneCompleted = true
          break
        case 3:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.stepTwoCompleted = true
          break
        case 4:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.stepThreeCompleted = true
          break
      }
      this.$router.push({ name: `${route}${step}` })
    },
    handleShowMenu() {
      this.is_show_menu = !this.is_show_menu
    },
    getProgress() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/progress/${id}`)
        .then(res => {
          this.$store.commit('auth/setStep', res.data)
          this.loaded = true
        })
    },
    getStarted() {
      this.$router.push({ name: 'get-started' })
    },
    navigateToAccount() {
      this.navigateToHome(this.$store.getters['auth/userType'])
    },
    showConfirm() {
      this.show = true
    },
    viewProfile() {
      this.$router.push({ name: 'profile' })
    },
    goToRegister() {
      let route
      switch (this.type) {
        case 0:
          route = 'register'
          break
        case 1:
          route = 'agent-register'
          break
        case 3:
          route = 'team-register'
          break
        default:
          route = 'register'
      }
      this.$router.push({ path: `/auth/${route}` })
    },
    scrollToEl(id) {
      if (this.$route.name !== 'welcome') {
        this.$router.push({ name: 'welcome', params: { id } })
      } else {
        document.getElementById(id)
          .scrollIntoView()
      }
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName })
    },
    logout() {
      this.$http
        .post('/api/auth/logout').then(res => {
          this.$store.commit('auth/clearAuthState')
          const isStaff = localStorage.getItem('isStaff')
          if (isStaff === 'true') {
            this.$router.push({ name: 'staff-login' })
          } else {
            this.$router.push({ name: 'login' })
          }
        })
    },
    getSiteContents() {
      this.$http.get('api/site_contents')
        .then(res => {
          this.$store.commit('auth/setSiteContents', res.data)
          this.loaded = true
        })
    },
  },
}
</script>
