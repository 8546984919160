<template>
  <div>

    <section class="site-subscribe-view">
      <div class="container">
        <div class="sub-view">
          <div class="sub-text">
            <h4>Do You Want to Get Update What’s Upcoming?</h4>
          </div>
          <validation-observer ref="contactForm">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <div class="sub-form">

                <div class="sub-text-box">

                  <input

                    v-model="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                  >

                  <div
                    class="s-form-btn"
                    @click="validationForm"
                  >
                    <b-spinner
                      v-if="newsLetterLoading"
                      small
                      type="grow"
                    />
                    {{ newsLetterLoading?'Loading...':'Subscribe Now' }}

                  </div>

                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </div>

      </div>
    </section></div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      newsLetterLoading: false,
      required,
      email,
    }
  },
  methods: {
    subscribe() {
      this.newsLetterLoading = true
      this.$http
        .post('/api/newsletter', { email: this.email })
        .then(res => {
          this.newsLetterLoading = false
          this.$bvToast.toast('Subscribed Successfully', {
            title: 'Newsletter',
            variant: 'success',
            solid: true,
          })
          this.email = ''
        })
        .catch(err => {
          this.newsLetterLoading = false
          console.log(err)
        })
    },
    validationForm() {
      this.$refs.contactForm.validate()
        .then(success => {
          if (success) {
            this.subscribe()
          }
        })
    },
  },
}
</script>

<style>

</style>
